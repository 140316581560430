<template>
  <div id="fixed-fields">
    <div class="title">Sabit Alanlar</div>

    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }"><i class="icon-home"></i></el-breadcrumb-item>
        <el-breadcrumb-item>Web Sitesi</el-breadcrumb-item>
        <el-breadcrumb-item>Sabit Alanlar</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <div class="general">
      <div class="part">
        <div class="header">Dil Seçiniz</div>
        <el-select v-model="texts.LanguageCode" style="width: 100%" @change="setTexts">
          <el-option v-for="item in languageList" :key="item.ID" :label="item.Title" :value="item.LanguageCode" style="display: flex; align-items: center">
            <img :src="item.Image" style="height: 20px; width: 30px; margin-right: 10px" />
            {{ item.Title }}
          </el-option>
        </el-select>
      </div>

      <div class="part">
        <div class="header">Ana sayfadaki not bölümü:</div>
        <el-input type="textarea" :rows="4" placeholder="Bir şeyler yazın..." v-model="texts.HomeNote"> </el-input>
        <!-- <div class="text-edit">
          <quill-editor ref="myQuillEditor" :options="editorOption" v-model="anaSayfaNot"> </quill-editor>
        </div> -->
        <div class="button-group-admin">
          <el-button class="confirmReportAdmin" type="primary" @click="update"><i class="icon-send"></i>Kaydet</el-button>
        </div>
      </div>
      <div class="part">
        <div class="header">Hakkımızda bölümü:</div>
        <!-- <el-input type="textarea" autosize placeholder="Bir şeyler yazın..." v-model="aboutUsNote"> </el-input> -->
        <quill-editor ref="myQuillEditor" v-model="texts.About"> </quill-editor>

        <div class="button-group-admin">
          <el-button class="confirmReportAdmin" type="primary" @click="update"><i class="icon-send"></i>Kaydet</el-button>
        </div>
      </div>
      <div class="part">
        <div class="header">Anasayfadaki Maddeler:</div>
        <!-- <el-input type="textarea" autosize placeholder="Bir şeyler yazın..." v-model="aboutUsNote"> </el-input> -->

        <div v-for="(item, index) in texts.HeaderItems" :key="index">
          <div class="top">
            <div class="mid-text">Madde {{ index + 1 }}</div>

            <el-button class="delete" @click="deleteItem(index)"> <i class="icon-delete-fixed"></i> </el-button>
          </div>
          <quill-editor ref="myQuillEditor" v-model="texts.HeaderItems[index].Text"> </quill-editor>
        </div>

        <div class="button-group-admin">
          <el-button class="confirmReportAdmin" type="primary" @click="update"><i class="icon-send"></i>Kaydet</el-button>
          <el-button class="confirmReportAdmin" type="primary" @click="addItem"><i class="icon-plus"></i>Madde Ekle</el-button>
        </div>
      </div>

      <div class="part">
        <div class="header">Mobildeki çalışma saatleri yazısı bölümü:</div>
        <el-input type="textarea" :rows="4" placeholder="Bir şeyler yazın..." v-model="texts.WorkingHoursText"> </el-input>
        <div class="button-group-admin">
          <el-button class="confirmReportAdmin" type="primary" @click="update"><i class="icon-send"></i>Kaydet</el-button>
        </div>
      </div>

      <div class="part">
        <div class="header">Mobildeki hafta içi bölümü:</div>
        <el-input type="textarea" :rows="4" placeholder="Bir şeyler yazın..." v-model="texts.WorkingDaysText"> </el-input>
        <div class="button-group-admin">
          <el-button class="confirmReportAdmin" type="primary" @click="update"><i class="icon-send"></i>Kaydet</el-button>
        </div>
      </div>

      <div class="part">
        <div class="header">Mobildeki çalışma saatleri bölümü:</div>
        <el-input type="textarea" :rows="4" placeholder="Bir şeyler yazın..." v-model="texts.WorkingHoursRangeText"> </el-input>
        <div class="button-group-admin">
          <el-button class="confirmReportAdmin" type="primary" @click="update"><i class="icon-send"></i>Kaydet</el-button>
        </div>
      </div>

      <div class="part">
        <div class="header">Mobildeki hemen görüşme yazısı bölümü:</div>
        <el-input type="textarea" :rows="4" placeholder="Bir şeyler yazın..." v-model="texts.WorkingDescriptionText"> </el-input>
        <div class="button-group-admin">
          <el-button class="confirmReportAdmin" type="primary" @click="update"><i class="icon-send"></i>Kaydet</el-button>
        </div>
      </div>

      <div class="part">
        <div class="header">Mobildeki yeni görüşme başlat butonu yazısı bölümü:</div>
        <el-input type="textarea" :rows="4" placeholder="Bir şeyler yazın..." v-model="texts.NewAppointmentButtonText"> </el-input>
        <div class="button-group-admin">
          <el-button class="confirmReportAdmin" type="primary" @click="update"><i class="icon-send"></i>Kaydet</el-button>
        </div>
      </div>

      <div class="part">
        <div class="header">Hemen görüşme başlat sekmesi yazısı:</div>
        <el-input type="textarea" :rows="4" placeholder="Bir şeyler yazın..." v-model="texts.StartInstantAppointmentText"> </el-input>
        <div class="button-group-admin">
          <el-button class="confirmReportAdmin" type="primary" @click="update"><i class="icon-send"></i>Kaydet</el-button>
        </div>
      </div>

      <div class="part">
        <div class="header">Randevulu uzman gorusmesı sekmesi yazısı:</div>
        <el-input type="textarea" :rows="4" placeholder="Bir şeyler yazın..." v-model="texts.StartPlannedAppointmentText"> </el-input>
        <div class="button-group-admin">
          <el-button class="confirmReportAdmin" type="primary" @click="update"><i class="icon-send"></i>Kaydet</el-button>
        </div>
      </div>

      <div class="part">
        <div class="header">Anasayfa Tanıtım Yazısı Başlığı(Mobil):</div>
        <quill-editor ref="myQuillEditor" :options="editorOption" v-model="texts.PresentationDescriptionHeaderText"> </quill-editor>
        <div class="button-group-admin">
          <el-button class="confirmReportAdmin" type="primary" @click="update"><i class="icon-send"></i>Kaydet</el-button>
        </div>
      </div>
      <div class="part">
        <div class="header">Anasayfa Tanıtım Yazısı(Mobil):</div>
        <quill-editor ref="myQuillEditor" :options="editorOption" v-model="texts.PresentationDescriptionText"> </quill-editor>
        <div class="button-group-admin">
          <el-button class="confirmReportAdmin" type="primary" @click="update"><i class="icon-send"></i>Kaydet</el-button>
        </div>
      </div>
      <!--++14/02/2023 NC Tanıtım yazısı presentation sayfası mobil ve web olarak ayrıldı.Aşağıda web yer almaktadır.-->
      <div class="part">
        <div class="header">Anasayfa Tanıtım Yazısı Başlığı(Web):</div>
        <quill-editor ref="myQuillEditor" :options="editorOption" v-model="texts.PresentationDescriptionHeaderTextWeb"> </quill-editor>
        <div class="button-group-admin">
          <el-button class="confirmReportAdmin" type="primary" @click="update"><i class="icon-send"></i>Kaydet</el-button>
        </div>
      </div>
      <div class="part">
        <div class="header">Anasayfa Tanıtım Yazısı(Web):</div>
        <quill-editor ref="myQuillEditor" :options="editorOption" v-model="texts.PresentationDescriptionTextWeb"> </quill-editor>
        <div class="button-group-admin">
          <el-button class="confirmReportAdmin" type="primary" @click="update"><i class="icon-send"></i>Kaydet</el-button>
        </div>
      </div>

      <div class="part">
        <div class="header">Ödeme Sayfası Başlık Yazısı(Mobil):</div>
        <el-input type="textarea" :rows="4" placeholder="Bir şeyler yazın..." v-model="texts.AdditionalPriceHeaderText"> </el-input>
        <div class="button-group-admin">
          <el-button class="confirmReportAdmin" type="primary" @click="update"><i class="icon-send"></i>Kaydet</el-button>
        </div>
      </div>

      <div class="part">
        <div class="header">SEO (Search Engine Optimization) Alanı:</div>
        <quill-editor ref="myQuillEditor" :options="editorOption" v-model="texts.SeoTextWeb"> </quill-editor>
        <div class="button-group-admin">
          <el-button class="confirmReportAdmin" type="primary" @click="update"><i class="icon-send"></i>Kaydet</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      languageList: [],
      ValidationErrors: [],
      texts: {
        ID: null,
        HomeNote: null,
        About: null,
        HeaderItems: [],
        WorkingHoursText: null,
        WorkingDaysText: null,
        WorkingHoursRangeText: null,
        WorkingDescriptionText: null,
        NewAppointmentButtonText: null,
        StartInstantAppointmentText: null,
        StartPlannedAppointmentText: null,
        PresentationDescriptionHeaderText: null,
        PresentationDescriptionText: null,
        PresentationDescriptionHeaderTextWeb: null,
        PresentationDescriptionTextWeb: null,
        LanguageCode: null,
        AdditionalPriceHeaderText: null,
        SeoTextWeb: null,
      },
      allDatas: [],
    };
  },
  async beforeMount() {
    await this.getLanguageList();
    await this.getData();
    this.texts.LanguageCode = "tr";
    if (this.allDatas.length > 0) this.texts = JSON.parse(JSON.stringify(this.allDatas.find((x) => x.LanguageCode == this.texts.LanguageCode)));
  },
  methods: {
    async getData() {
      var res = await this.$client.post("/AboutUs/GetForAdmin");
      if (res.data.HasError) {
        this.$message.error(res.data.Message);
      } else {
        this.allDatas = res.data.Data.Items;
      }
    },
    async getLanguageList() {
      var res = await this.$client.post("/Localization/SystemLanguages");
      if (res.data.HasError) {
        this.$message.error(res.data.Message);
      } else {
        this.languageList = res.data.Data.Items;
      }
    },
    setTexts() {
      console.log(this.allDatas.some((x) => x.LanguageCode == this.texts.LanguageCode));
      if (this.allDatas.some((x) => x.LanguageCode == this.texts.LanguageCode)) {
        this.texts = JSON.parse(JSON.stringify(this.allDatas.find((x) => x.LanguageCode == this.texts.LanguageCode)));
      } else {
        this.texts = {
          ID: null,
          HomeNote: null,
          About: null,
          HeaderItems: [],
          WorkingHoursText: null,
          WorkingDaysText: null,
          WorkingHoursRangeText: null,
          WorkingDescriptionText: null,
          NewAppointmentButtonText: null,
          StartInstantAppointmentText: null,
          StartPlannedAppointmentText: null,
          PresentationDescriptionHeaderText: null,
          PresentationDescriptionText: null,
          PresentationDescriptionHeaderTextWeb: null,
          PresentationDescriptionTextWeb: null,
          AdditionalPriceHeaderText: null,
          LanguageCode: this.texts.LanguageCode,
          SeoTextWeb: null,
        };
        console.log(this.texts.LanguageCode);
      }
    },
    addItem() {
      this.texts.HeaderItems.push({
        Text: null,
      });
    },
    deleteItem(index) {
      this.texts.HeaderItems.splice(index, 1);
    },
    async update() {
      var payload = {
        ID: this.texts.ID,
        HomeNote: this.texts.HomeNote,
        About: this.texts.About,
        HeaderItems: this.texts.HeaderItems,
        WorkingHoursText: this.texts.WorkingHoursText,
        WorkingDaysText: this.texts.WorkingDaysText,
        WorkingHoursRangeText: this.texts.WorkingHoursRangeText,
        WorkingDescriptionText: this.texts.WorkingDescriptionText,
        NewAppointmentButtonText: this.texts.NewAppointmentButtonText,
        StartInstantAppointmentText: this.texts.StartInstantAppointmentText,
        StartPlannedAppointmentText: this.texts.StartPlannedAppointmentText,
        PresentationDescriptionHeaderText: this.texts.PresentationDescriptionHeaderText,
        PresentationDescriptionText: this.texts.PresentationDescriptionText,
        PresentationDescriptionHeaderTextWeb: this.texts.PresentationDescriptionHeaderTextWeb,
        PresentationDescriptionTextWeb: this.texts.PresentationDescriptionTextWeb,
        LanguageCode: this.texts.LanguageCode,
        AdditionalPriceHeaderText: this.texts.AdditionalPriceHeaderText,
        SeoTextWeb: this.texts.SeoTextWeb,
      };
      var res = await this.$client.post("/AboutUs/AddUpdate", payload);
      this.ValidationErrors = res.data.ValidationErrors;
      if (res.data.HasError) {
        this.$message.error(res.data.Message);
      } else {
        this.$message.success(res.data.Message);
        await this.getData();
      }
    },
  },
};
</script>

<style></style>
